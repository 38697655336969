<template>
  <div class="menus">
    <div class="tian">
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        size="mini"
        @click="addition()"
        style="float: left"
        v-hasPermi="['system:menu:add']"
        >添加</el-button
      >
    </div>
    <!-- 页面table部分 -->
    <el-table
      :data="getSearchInfo"
      style="width: 100%"
      row-key="menuId"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="menuName" label="名称" sortable></el-table-column>
      <el-table-column prop="url" label="路径" sortable> </el-table-column>
      <el-table-column prop="icon" label="图标"> </el-table-column>
      <el-table-column prop="visible" label="状态">
        <template slot-scope="scope">
          <el-tag type="primary" plain v-if="scope.row.visible === '0'"
            >正常</el-tag
          >
          <el-tag type="danger" plain v-else>停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
            v-hasPermi="['system:menu:edit']"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
            v-hasPermi="['system:menu:remove']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- ---------------添加模块----------------------------------- -->
    <template>
      <el-dialog
        title="添加菜单"
        :visible.sync="dialogFormVisible"
        width="600px"
      >
        <el-form>
          <el-form-item label="上级菜单" :label-width="formLabelWidth" required>
            <el-select
              v-model="treeData"
              placeholder="请选择..."
              style="width: 16rem"
            >
              <el-option :value="treeDataValue" style="height: auto">
                <el-tree
                  ref="tree"
                  :data="treeForm"
                  :expand-row-keys="[0]"
                  node-key="id"
                  default-expand-al="expand"
                  :props="defaultProps"
                  @node-click="handleNodeClick"
                />
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="菜单类型" :label-width="formLabelWidth">
            <el-radio-group v-model="form.menuType">
              <el-radio label="M">目录</el-radio>
              <el-radio label="C">菜单</el-radio>
              <el-radio label="F">按钮</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="菜单名称" :label-width="formLabelWidth" required>
            <el-input
              v-model="form.menuName"
              placeholder="请输入菜单名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示顺序" :label-width="formLabelWidth">
            <el-input
              v-model="form.orderNum"
              placeholder="请输入显示顺序"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="路径"
            v-if="form.menuType != 'F'"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.url"
              placeholder="请输入路径"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="图标"
            v-if="form.menuType == 'M'"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="form.icon"
              placeholder="请输入图标"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="菜单状态" :label-width="formLabelWidth">
            <el-radio-group v-model="form.visible">
              <el-radio :label="0">显示</el-radio>
              <el-radio :label="1">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="权限标识" :label-width="formLabelWidth" required>
            <el-input
              v-model="form.perms"
              placeholder="请输入权限标识"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="add()">确 定</el-button>
        </div>
      </el-dialog>
    </template>
    <!-- ---------------编辑模块----------------------------------- -->
    <template>
      <el-dialog title="编辑" :visible.sync="editFormVisible" width="600px">
        <el-form :model="editForm">
          <el-form-item label="上级菜单" :label-width="formLabelWidth" required>
            <el-select
              v-model="treeData"
              placeholder="请选择..."
              style="width: 16rem"
            >
              <el-option :value="treeDataValue" style="height: auto">
                <el-tree
                  ref="tree"
                  :data="treeForm"
                  :expand-row-keys="[0]"
                  node-key="id"
                  default-expand-al="expand"
                  :props="defaultProps"
                  @node-click="handClick"
                />
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="菜单类型" :label-width="formLabelWidth" required>
            <el-radio-group v-model="editForm.menuType">
              <el-radio label="M">目录</el-radio>
              <el-radio label="C">菜单</el-radio>
              <el-radio label="F">按钮</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="菜单名称" :label-width="formLabelWidth" required>
            <el-input
              v-model="editForm.menuName"
              placeholder="请输入 菜单名称"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="显示顺序" :label-width="formLabelWidth">
            <el-input
              v-model="editForm.orderNum"
              placeholder="请输入显示顺序"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="路径"
            v-if="editForm.menuType != 'F'"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="editForm.url"
              placeholder="请输入路径"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="图标"
            v-if="editForm.menuType == 'M'"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="editForm.icon"
              placeholder="请输入图标"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="菜单状态" :label-width="formLabelWidth">
            <el-radio-group v-model="editForm.visible">
              <el-radio label="0">显示</el-radio>
              <el-radio label="1">隐藏</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="权限标识" :label-width="formLabelWidth" required>
            <el-input
              v-model="editForm.perms"
              placeholder="请输入权限标识"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="redactSubmint()">确 定</el-button>
        </div>
      </el-dialog>
    </template>
  </div>
</template>
<script>
import request from '../../utils/request.js'
import { handleTree } from '../../utils/ruoyi.js'
export default {
  data() {
    return {
      treeData: '',
      treeDataValue: '',
      dialogFormVisible: false,
      editFormVisible: false,
      formLabelWidth: '120px',
      expand: false,
      treeForm: [],
      form: {
        menuName: '',
        parentId: '',
        orderNum: '',
        url: '',
        icon: '',
        menuType: 'M',
        visible: 0,
        perms: '',
      },
      editForm: {
        menuId: '',
        menuName: '',
        parentId: '',
        orderNum: '',
        url: '',
        icon: '',
        menuType: 'M',
        visible: 0,
        perms: '',
      },
      getSearchInfo: [], //进入页面获取数据存放
      defaultProps: {
        children: 'children',
        label: 'menuName',
      },
    }
  },
  created() {
    this.Nav()
  },

  methods: {
    handleNodeClick(data) {
      this.treeDataValue = data.menuId
      this.treeData = data.menuName
      // console.log(this.treeData)
      // console.log(this.treeDataValue)
      this.form.parentId = this.treeDataValue
      // console.log(this.form.menuId)
    },
    handClick(data) {
      this.treeDataValue = data.menuId
      this.treeData = data.menuName
      // console.log(this.treeData)
      // console.log(this.treeDataValue)
      this.editForm.parentId = this.treeDataValue
      // console.log(this.form.menuId)
    },
    //获取导航信息
    Nav() {
      return request({
        url: 'menu/selectMenuList?menuName&visible',
        method: 'get',
      })
        .then((res) => {
          if (res.status == 200) {
            let navrout = res.data
            this.getSearchInfo = navrout
            // console.log(this.getSearchInfo )
          }
        })
        .catch(() => {})
    },
    //编辑弹窗
    handleEdit(index, row) {
      //    console.log(row)
      this.editFormVisible = true
      this.editForm = Object.assign({}, row)
      this.parent()
      this.treeDataValue = row.menuId
      this.treeData = row.menuName
      //    console.log(typeof(this.treeData))
      this.editForm.menuId = row.menuId
      //    console.log(this.editForm.menuId);
    },
    // 编辑确定
    redactSubmint() {
      // console.log(this.editForm)
      let datas = {
        menuId: this.editForm.menuId,
        menuName: this.editForm.menuName,
        parentId: this.editForm.parentId,
        orderNum: this.editForm.orderNum,
        url: this.editForm.url,
        menuType: this.editForm.menuType,
        visible: this.editForm.visible,
        perms: this.editForm.perms,
        icon: this.editForm.icon,
      }
      // console.log(datas)
      return request({
        url: 'menu/updateMenuById',
        method: 'put',
        data: datas,
      })
        .then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '修改成功',
              type: 'success',
            })
            this.Nav()
            this.editFormVisible = false
          }
        })
        .catch(() => {})
    },
    // 删除
    handleDelete(index, row) {
      //   console.log(index, row);
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            menuId: row.menuId,
          }
          return request({
            url: 'menu/deleteMenuById',
            method: 'delete',
            data: data,
          })
            .then((res) => {
              if (res.status == 200) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                })
                this.Nav()
              }
            })
            .catch(() => {})
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //获取弹窗树形结构

    parent() {
      return request({
        url: 'menu/treeSelect',
        method: 'get',
      })
        .then((res) => {
          if (res.status == 200) {
            //     this.treeForm = res.data
            // console.log(this.treeForm)

            this.treeForm = []
            const menu = { menuId: 0, menuName: '主类目', children: [] }
            menu.children = handleTree(res.data, 'menuId')
            this.treeForm.push(menu)
            // console.log(this.treeForm)

            //    this.Nav();
            //    this.dialogFormVisible= false
          }
        })
        .catch(() => {})
    },

    addition() {
      this.empty()
      this.dialogFormVisible = true
      this.parent()
    },
    //清空添加表单
    empty() {
      this.form.menuName = ''
      this.form.parentId = ''
      this.form.orderNum = ''
      this.form.url = ''
      this.form.icon = ''
      this.form.perms = ''
      this.form.menuType = 'M'
      this.form.visible = 0
      this.treeData = ''
    },
    // 添加弹窗确定提交
    add() {
      if (this.treeDataValue == '') {
        this.$message({ message: '请选择上级菜单', type: 'warning' })
      } else if (this.form.menuName == '') {
        this.$message({ message: '请输入菜单名称', type: 'warning' })
      } else if (this.form.orderNum == '') {
        this.$message({ message: '请输入显示顺序', type: 'warning' })
      } else if (this.form.perms == '') {
        this.$message({ message: '请输入权限标识', type: 'warning' })
      } else {
        return request({
          url: 'menu/insertMenu',
          method: 'post',
          data: this.form,
        })
          .then((res) => {
            if (res.status == 200) {
              this.$message({
                message: '添加成功',
                type: 'success',
              })
              this.Nav()
              this.dialogFormVisible = false
              this.treeDataValue = ''
              this.empty()
            }
          })
          .catch(() => {})
      }
    },
  },
}
</script>
<style>
.el-main {
  padding: 6px;
}

.tian {
  width: 100%;
  height: 60px;
}

.el-form {
  height: auto;
}
.el-dialog {
  width: 500px;
}
.el-select {
  width: 100% !important;
}
.el-radio-group {
  float: left;
  line-height: 50px;
}
</style>
